.notion {
    padding-bottom: 2rem;
}

.notion-who {
    text-decoration: none !important;
    font-weight: 600;
}

.categoryText {
    text-decoration: none !important;
}

.middleCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
