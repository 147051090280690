/*
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charmonman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&family=EB+Garamond:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
*/
/*@import url('https://fonts.googleapis.com/css2?family=Sono:wght@400&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow&family=Major+Mono+Display&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

html {
  font-size: 125%; /* 16px × 1.25 = 20px */
  font-style: oblique;
}

body {
  color: unset;
}

.reenie-beanie-regular {
  font-family: "Reenie Beanie", serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  /*font-family: 'Amiri', sans;*/
  /*font-family: 'Rubik', sans-serif;*/
  /*font-family: 'Sono', monospace;*/
  /*font-family: 'Red Hat Mono', monospace;*/
  /*font-family: 'PT Sans', sans-serif;*/
  /*font-family: 'Barlow', sans-serif;*/
  font-family: "Reenie Beanie", serif;
  min-height: 100vh;
}

.container-full {
  width: 100%;
  max-width: 100%;
  clear: both;
  margin: 0 auto;
}

.middleCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.middleRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.middleLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

body {
  /*background-image : url(https://i.imgur.com/7eSx6i2.jpg?background=ffffffff&noise=5c5c5c&density=78&opacity=12);*/
}