.middleCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.postMainTitle {
    font-size: 3rem;
    text-shadow: 0 0 1px black;
}

.postCategory {
    font-size: 1rem;
}

img {
    display: block;
    margin: auto auto;
    justify-content: center;
    max-width: 100%;
}

.postMainContent {
    text-shadow: 0 0 1px black;
}

/* Headings */
.postMainContent > div > h1 {
    font-size: 3rem; /* 60px */
    line-height: 1.2; /* 72px */
    margin-bottom: 1rem;
}

.postMainContent > div > h2 {
    font-size: 2.5rem; /* 50px */
    line-height: 1.3; /* 65px */
    margin-bottom: 0.8rem;
}

.postMainContent > div > h3 {
    font-size: 2rem; /* 40px */
    line-height: 1.4; /* 56px */
    margin-bottom: 0.7rem;
}

.postMainContent > div > h4 {
    font-size: 1.75rem; /* 35px */
    line-height: 1.4; /* 49px */
    margin-bottom: 0.6rem;
}

.postMainContent > div > h5 {
    font-size: 1.5rem; /* 30px */
    line-height: 1.5; /* 45px */
    margin-bottom: 0.5rem;
}

.postMainContent > div > h6 {
    font-size: 1.25rem; /* 25px */
    line-height: 1.5; /* 37.5px */
    margin-bottom: 0.4rem;
}

/* Paragraphs */
.postMainContent > div > p {
    font-size: 1.5rem;
    line-height: 1; /* 30px */
    margin-bottom: 1rem;
}

/* Preformatted text */
pre {
    font-size: 0.7rem; /* 18px */
    /*line-height: 1.6; !* 28.8px *!*/
    /*padding: 1rem;*/
    /*border-radius: 0.5rem;*/
    overflow: auto;
    /*margin-bottom: 1.5rem;*/
}

/* Inline code */
code {
    font-size: 0.7rem; /* 18px */
    /*background-color: #f5f5f5;*/
    /*padding: 0.2rem 0.4rem;*/
    /*border-radius: 0.3rem;*/
    font-family: monospace;
}

/* Blockquotes */
blockquote {
    font-size: 1.25rem; /* 25px */
    line-height: 1; /* 40px */
    margin-left: 1.5rem;
    padding-left: 1rem;
    border-left: 4px solid #ccc;
    color: darkgreen;
}

.ReactTags__tagInput {
    width: 100%;
}
