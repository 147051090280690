.homecard {
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);*/
    transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
    border: none !important;
    border-radius: 0 !important;
}

.card-text {
    /*text-shadow: 0 0 5px rgba(0, 0, 0, 1);*/
    border-radius: 10px;
    -webkit-text-stroke: 1px rgba(0,0,0, 0.1);
}

.card-date {
    /*font-family: 'Noto Sans', sans-serif;*/
    margin-bottom: -10px;
}

.card-foot {
    /*font-family: 'Noto Sans', sans-serif;*/
}

.homecard:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.card-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    border-bottom: 0 !important;
    color: black;
    font-size: 0.9rem;
}

.card-body {
    line-height: 1;
    font-size: 1.25rem;
    mix-blend-mode: difference;
}

.card-footer {
    padding: 0 0 0 !important;
    border-top: 2px black !important;
    font-size: 0.9rem;
}
